import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http'
import { LOADIPHLPAPI } from 'dns';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})

export class ApiService
{
	authToken: any = this.getAccessToken()
	baseURL: string = environment.API_SERVICE_URL

	list: any 	// to get filtered data of either schools, districts, students or teachers
	userObject: any;

	constructor(
		private http: HttpClient
	) { }


	// --------------------------------------------------------------
	// 							Authentication
	// --------------------------------------------------------------


	
	uploadTeacher(file):Observable<any>{
		const formData = new FormData();
  		console.log("formdatat before",formData);
  
		// Store form name as "file" with file data
		formData.append("file", file, file.name);
		return this.http.post(this.baseURL + 'super-admin/upload-teacherList', formData)
	}
	
	uploadStudent(file):Observable<any>{
		const formData = new FormData();
  		console.log("formdatat before",formData);
  
		// Store form name as "file" with file data
		formData.append("file", file, file.name);
		return this.http.post(this.baseURL + 'super-admin/upload-studentList', formData)
	}
	
	getAccessToken()
	{
		if (localStorage.getItem('token'))
		{
			this.authToken = localStorage.getItem('token').split('=')[0]

		} else
		{
			return null
		}
		return localStorage.getItem('token').split('=')[0]
	}

	setAccessToken(token: string)
	{
		localStorage.setItem('token', token)
		console.log("User Token saved. ")
	}

	async loginUser(data: any)
	{
		console.log("Request Received...")
		return await this.http.post(this.baseURL + 'user/login', data)
			.toPromise()
			.then(res => res)
	}

	logOut()
	{
		console.log('b')
		this.authToken = null
		localStorage.clear()
	}

	isLoggedIn()
	{
		console.log(this.getAccessToken() !== null)
		return this.getAccessToken() !== null
	}

	isLoggedOut()
	{
		return this.getAccessToken() === null
	}


	/**
	 * Forgot Password functionality
	 * @param data data to be sent to the service
	 * @returns object
	 */
	async forgotPassword(data: any)
	{
		return await this.http.post(this.baseURL + 'user/forget-password', data)
			.toPromise()
			.then(res => res)
	}


	/**
	 *
	 * @param data data
	 * @returns object
	 */
	async resetPassword(data: any)
	{
		return await this.http.post(this.baseURL + 'user/reset-password', data)
			.toPromise()
			.then(res => res)
	}










	// ----------------------------------------------------------------
	// 									Search
	// ----------------------------------------------------------------



	// async search(data?: any)
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return await this.http.post(this.baseURL + "cases/cases-list", data, headers)
	// 		.toPromise()
	// 		.then(res => res)
	// }




	// ---------------------------------------------------------------------
	// 								Upload Service
	// ---------------------------------------------------------------------

	async sendExcelData(json_data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		console.error("\n\n URL not set yet. ")
		// return await this.http.post(`${this.baseURL}`, json_data, headers)
		// 	.toPromise()
	}




	// ------------------------------------------------------------
	// 							Misc
	// ------------------------------------------------------------
	// sendList()
	// {
	// 	return this.list
	// }








	// -----------------------------------------------------------------------
	// 									Districts
	// -----------------------------------------------------------------------

	/**
	 * retrieve all districts from database which matches search text. Empty text will return all
	 * @param searchData search text to find specific data
	 * @returns list of all districts [no unique data]
	 */
	async getAllDistricts(searchData: any)
	{
		let headers: any = {
			params: searchData,
			headers: {
				Authorization: this.authToken
			}
		}
		return await this.http.get(this.baseURL + "super-admin/district", headers)
			.toPromise()
	}

	getAllStates()
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(this.baseURL +"super-admin/state", headers)
			.toPromise()
			.then(res => res)
	}

	// /**
	//  * @returns list of all districts [ unique data ]
	//  */
	// async getUniqueDistricts()
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return await this.http.get(this.baseURL + "super-admin/getUniqueDistrict", headers)
	// 		.toPromise()
	// }

	/**
	 * register a new district into database
	 * @param data data of the new district to be added
	 * @returns success message or error, on fail otherwise
	 */
	async addDistrict(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.post(this.baseURL + "super-admin/add-district", data, headers)
			.toPromise()
			.then(res => res)
	}

	/**
	 * updates the data of the district
	 * @param id id of the data to be updated
	 * @param data data to be updated
	 * @param data1 query params stateId,cityId
	 * @returns success message or error, on fail otherwise
	 */
	async updateDistrict(id: string | number, data: any, data1?: any)
	{
		let headers: any = {
			params: data1,
			headers: {
				Authorization: this.authToken
			}
		}
		return await this.http.put(this.baseURL + "super-admin/update-district/" + id, data, headers)
			.toPromise()
			.then(res => res)
	}

	/**
	 *
	 * @param id id of the data to be deleted
	 * @param data data to be deleted
	 * @returns success message or error, on fail otherwise
	 */
	deleteDistrict(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(this.baseURL + "super-admin/district/" + id, headers)
			.toPromise()
			.then(res => res)
	}


	

	// ----------------------------------------------------------------------------
	// 											School - classroom
	// ----------------------------------------------------------------------------

	async getSchools(data: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.get(this.baseURL + 'super-admin/school', headers)
			.toPromise()
			.then(res => res)
	}

	addSchool(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(this.baseURL + 'super-admin/school', data, headers)
			.toPromise()
			.then(res => res)
	}

	editSchool(id: string | number, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.put(`${this.baseURL}super-admin/school/${id}`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteSchool(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}super-admin/school/${id}`, headers)
			.toPromise()
			.then(res => res)
	}


	// ----------------------------------------------------------------------------
	// 											Classroom
	// ----------------------------------------------------------------------------

	getClassroom(_id: string)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}admin/classroom-by-school/${_id}`, headers)
			.toPromise()
	}


	addClassroom(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}admin/classroom`, data, headers)
			.toPromise()
			.then(res => res)
	}

	editClassroom(classroom_id: string, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}admin/classroom/${classroom_id}`, data, headers)
			.toPromise()
			.then(res => res)
	}


	uploadClassroom(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}admin/classroom/upload-excel`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteClassroom(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}admin/classroom/${id}`, headers)
			.toPromise()
			.then(res => res)
	}



	// ----------------------------------------------------------------
	// 								Attendance
	// ----------------------------------------------------------------
	addAttendance(data: any)
	{
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.post(`${this.baseURL}admin/add-attendance/`, data, headers)
			.toPromise()
	}

	getStudentsList(data: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		};
		return this.http.get(this.baseURL + 'admin/get-class-attendance/', headers)
			.toPromise()
	}


	// ----------------------------------------------------------------
	// 								Admin
	// ----------------------------------------------------------------


	getAdmins(data: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(this.baseURL + 'super-admin/admin-list', headers)
			.toPromise()
			.then(res => res)
	}

	addAdmin(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}super-admin/add-admin`, data, headers)
			.toPromise()
			.then(res => res)
	}

	editAdmin(id: string | number, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}super-admin/admin/${id}`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteAdmin(id: string)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}super-admin/admin/${id}`, headers)
			.toPromise()
			.then(res => res)
	}


	// ------------------------------------------------------------------------------
	// 										Teacher
	// ------------------------------------------------------------------------------


	getAllTeachers(data: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}super-admin/teacher`, headers)
			.toPromise()
			.then(res => res)
	}


	addTeacher(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}super-admin/teacher`, data, headers)
			.toPromise()
			.then(res => res)
	}


	editTeacher(id: string | number, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.put(`${this.baseURL}super-admin/teacher/${id}`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteTeacher(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}super-admin/teacher/${id}`, headers)
			.toPromise()
			.then(res => res)
	}






	// --------------------------------------------------------------------------
	// 										Student
	// --------------------------------------------------------------------------

	async getStudents(data: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.get(`${this.baseURL}super-admin/student`, headers)
			.toPromise()
			.then(res => res);
	}


	addStudent(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return  this.http.post(`${this.baseURL}super-admin/student`, data, headers)
			.toPromise()
			.then(res => res)
	}

	editStudent(id: string | number, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return  this.http.put(`${this.baseURL}super-admin/student/${id}`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteStudent(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return  this.http.delete(`${this.baseURL}super-admin/student/${id}`, headers)
			.toPromise()
			.then(res => res)
	}


	atendanceMetric(id: any) {
		console.log(id)
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.get(this.baseURL + 'user/attendance-metrics/' + id, headers)
			.toPromise()
	}

	// -----------------------------------------------------------------------------------
	// 									District Calendar
	// -----------------------------------------------------------------------------------


	async getDistrictCalendars()
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return await this.http.get(`${this.baseURL}admin/district-calendar`, headers)
			.toPromise()
			.then(res => res);
	}

	async addDistrictCalendar(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.post(`${this.baseURL}admin/district-calendar`, data, headers)
			.toPromise()
			.then(res => res);
	}

	editDistrictCalendar(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}admin/district-calendar/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}

	deleteDistrictCalendar(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(`${this.baseURL}admin/district-calendar/${id}`, headers)
			.toPromise()
			.then(res => res);
	}

	// -----------------------------------------------------------------------------------
	// 									District Calendar holiday component
	// -----------------------------------------------------------------------------------
	
	getHolidayList(id: any)
	{
		let headers: any = {
			params: {
				districtCalendarId: id
			},
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}admin/district-holiday`, headers)
			.toPromise()
			.then(res => res);
	}

	addHoliday(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}admin/district-holiday`, data, headers)
			.toPromise()
			.then(res => res);
	}

	editHoliday(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}admin/district-holiday/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}

	deleteHoliday(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(`${this.baseURL}admin/district-holiday/${id}`, headers)
			.toPromise()
			.then(res => res);
	}



	// -----------------------------------------------------------------------------------
	// 									District/School Calendar
	// -----------------------------------------------------------------------------------



	// getHolidayCalendars()
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return this.http.get(`${this.baseURL}admin/district-holiday`, headers)
	// 		.toPromise()
	// 		.then(res => res);
	// }

	// async addHolidayCalendar(data: any)
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return await this.http.post(`${this.baseURL}admin/district-holiday`, data, headers)
	// 		.toPromise()
	// 		.then(res => res);
	// }

	// async editHolidayCalendar(id: any, data: any)
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return await this.http.put(`${this.baseURL}admin/update-district-holiday/${id}`, data, headers)
	// 		.toPromise()
	// 		.then(res => res);
	// }

	// async deleteHolidayCalendar(id: any, data: any)
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return await this.http.put(`${this.baseURL}admin/district-holiday/${id}`, data, headers)
	// 		.toPromise()
	// }





	// addSchoolHolidayCalendar(data: any)
	// {
	// 	let headers = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return this.http.post(`${this.baseURL}admin/school-holiday`, data, headers)
	// 		.toPromise()
	// }




	// ---------------------------------------------------------------
	// 								School Calendar
	// ---------------------------------------------------------------

	async getSchoolCalendars(id: any)
	{
		let headers
		if(id=="none"){
			headers = {
				headers: {
					Authorization: this.authToken
				}
			}
		}
		else{
			 headers = {
				params: {
					schoolId: id
				},
				headers: {
					Authorization: this.authToken
				}
			}
		}
		

		return await this.http.get(`${this.baseURL}admin/school-calendar`, headers)
			.toPromise()
	}

	addSchoolCalendar(data: any)
	{
		let headers = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}admin/school-calendar`, data, headers)
			.toPromise()
	}

	editSchoolCalendar(_id: string, data: any)
	{
		let headers = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}admin/school-calendar/${_id}`, data, headers)
			.toPromise()
	}

	deleteSchoolCalendar(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(`${this.baseURL}admin/school-calendar/${id}`, headers)
			.toPromise()
			.then(res => res);
	}

	//---------------------------------------------------------------
	// 								school holiday calendar
	// ---------------------------------------------------------------

	getSchoolHolidayList(id: any)
	{
		let headers = {
			params: {
				schoolCalendarId: id
			},
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.get(`${this.baseURL}admin/school-holiday`, headers)
			.toPromise()
	}

	addSchoolHoliday(data: any)
	{
		let headers = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}admin/school-holiday`, data, headers)
			.toPromise()
	}

	editSchoolHoliday(id: string, data: any)
	{
		let headers = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}admin/school-holiday/${id}`, data, headers)
			.toPromise()
	}


	deleteSchoolHoliday(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(`${this.baseURL}admin/school-holiday/${id}`, headers)
			.toPromise()
			.then(res => res);
	}

	// ---------------------------------------------------------------
	// 								Profile
	// ---------------------------------------------------------------

	async getProfileInfo(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.get(`${this.baseURL}user/getProfile/${id}`, headers)
			.toPromise()
	}

	async updateProfile(id: any, data: any)
	{
		console.log('AuthToken: ', this.authToken)
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.put(`${this.baseURL}user/updateProfile/${id}`, data, headers)
			.toPromise()
	}

	setName(name: string)
	{
		localStorage.setItem('user_name', name)
		console.log('User Name has been changed. ')
	}

	getName()
	{
		return localStorage.getItem('user_name')
	}




	// ----------------------------------------------------------------
	// 									Misc
	// ----------------------------------------------------------------

	async getCityByState(districtName: string)
	{
		let headers: any = {
			params: {
				districtName: districtName
			},
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.get(`${this.baseURL}super-admin/getCityByDistrict`, headers)
			.toPromise()
			.then(res => res)
	}

	async getSchoolByCity(cityName: string)
	{
		let headers: any = {
			params: {
				physicalCity: cityName
			},
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.get(`${this.baseURL}super-admin/schoolByCity`, headers)
			.toPromise()
			.then(res => res)
	}


	getTeacherBySchool(schoolId: string)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return  this.http.get(`${this.baseURL}teacher/getTeacherBySchool/${schoolId}`, headers)
			.toPromise()
			.then(res => res)
	}

	async getSchoolByDistrict(data: any,search?:any)
	{
		let data1 = data.join(",")
		let headers: any = {
			params: {
				districtId: data1,
				search:search
			},
			headers: {
				Authorization: this.authToken
			}
		}
		console.log(data1);
		

		return await this.http.get(`${this.baseURL}super-admin/schoolByDistricts`, headers)
			.toPromise()
			.then(res => res)
	}

	getClassroomByTeacher(teacher_id){

		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}admin/classroom-by-teacher/${teacher_id}`, headers)
			.toPromise()
			.then(res => res)
	}




}
