<div class="container-fluid">
    <div class="row">
        <div class="header col-md-12">
            <div class="col-6 float-start">
                <img class="logo-image" draggable="false" src="../../assets/logo-image.png" (click)="home()">
                <img class="logo-text" draggable="false" src="../../assets/logo-text.png" (click)="home()">
            </div>
            <div class="col-6 float-end">
                <img class="header-triangle float-end" draggable="false" src="../../assets/header-triangle.png">
            </div>
        </div>
    </div>
</div>