import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{
	@Output() isEditingStudent = new EventEmitter<boolean>()

	email: any;
	password: any;
	user_name: any;		// set from localStorage
	isChecked: boolean = true
	show_password: boolean



	constructor(
		private api: ApiService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService
	) { }

	ngOnInit(): void
	{ }

	login()
	{
		console.log('User Log In...')
		this.spinner.show()
		if (!this.email || !this.password)
		{
			this.toastr.error('All Fields are required. ', 'Error!');
			this.spinner.hide()
		} else
		{
			let data = {
				email: this.email,
				password: this.password
			}

			this.api.loginUser(data).then((res: any) =>
			{
				if (res.status == 200 && res.data.role == "superadmin")
				{
					console.info('Getting Response [login()] -------', res)

					localStorage.setItem('role', res.data.role)

					this.user_name = `${res.data.firstName.charAt(0).toUpperCase()}${res.data.firstName.slice(1)} ${res.data.lastName.charAt(0).toUpperCase()}${res.data.lastName.slice(1)}`
					localStorage.setItem('user_name', this.user_name) // store user name

					this.api.setAccessToken(`${res.data.accessToken}`) // store access token.

					localStorage.setItem('user_id', res.data._id) // store user id


					this.isEditingStudent.emit(res.data.isAllowToEditStudent)  // emitting editing student event

					this.toastr.success(`Welcome back ${this.user_name}. `, "Success")
					this.router.navigate(['/dashboard'])
					this.spinner.hide()
				} else if (res.status != 200)
				{
					this.toastr.error(res.message, "Error !")
				} else
				{
					this.toastr.error("Authorization Error! You are not authorized to use this email.", "Error !")
				}
				this.spinner.hide()
			})
		}
	}
}
