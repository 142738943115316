import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './services/auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		HeaderComponent,
		ForgotPasswordComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		NgxSpinnerModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		NgMultiSelectDropDownModule.forRoot(),
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-center',
			timeOut: 2000,
			extendedTimeOut: 5000,
			closeButton: true,
			preventDuplicates: false
		}),

	],
	providers: [
		ApiService,
		AuthGuard
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
