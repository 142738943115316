import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare let $: any;
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit
{

	constructor(
		private router: Router
	) { }

	ngOnInit(): void
	{

		this.handleImgAccess()

	}

	handleImgAccess = () =>
	{
		let headerImg = document.getElementsByName('img');

		// prevent dragable functionality
		// headerImg.setAttribute('draggable', "false"); // handled in html itself

		// disable context menu for prevent save functionality
		$("img").on("contextmenu", headerImg, (e) => false);
	}


	home()
	{
		console.log('Lets Moveeeeee.....')
		console.clear()
		this.router.navigate(['/dashboard']);
	}

}
